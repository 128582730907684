<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" style="opacity: 1">
        <div data-testid="hero-video-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <div class="relative w-full h-full overflow-hidden max-h-[80vh] h-[520px] md:h-[650px] md:max-h-full w-full">
            <div autoplay="" style="width: 100%; height: 100%">
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/visionaries/b1.png')" alt="" />
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 dark:text-iblack-300 max-w-[500px]">
                Where the Dreamers<br class="hidden md:inline" />
                Are the Doers
              </h1>
              <p class="text-lg text-iblack-600 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                Our visionary leaders are creating tomorrow’s reality.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.blackline.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/visionaries/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Therese Put It All on the Line to Create Continuous Accounting
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Computer scientist Therese Tucker’s company Blackline is transforming the financial close
                            process.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/visionaries/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            With Datadog, Alexis and Olivier Harness the Power of Purity
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Learn how purity of purpose and vision has allowed this team to unite Dev and Ops by solving
                            their shared pain points.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden main-grid--in-grid"
          style="  transform: translateY(40px) translateZ(0px)"
        >
          <div
            data-testid="tout-image-bg"
            class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
          >
            <img style="width: 100%; height: 100%;" :src="require('@/assets/images/visionaries/b4.png')" alt="" />
          </div>
          <div
            class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
            >
              <div class="mt-[30px] md:my-0" style="  transform: translateY(20px) translateZ(0px)">
                <h1 class="text-2xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 md:dark:text-iblack-300">
                  Pieter and Adyen Team Started Over, to Service the World.
                </h1>
                <p
                  class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-400 md:text-iblack-400 mt-2.5 max-w-[500px]"
                >
                  By abandoning existing payment infrastructure, the Adyen team is ushering in a new digital era.
                </p>
                <div class="items-left justify-left">
                  <br /><a
                    draggable="false"
                    target="_blank"
                    class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                    href="https://www.adyen.com/"
                    ><span
                      ><svg
                        class="overflow-visible"
                        width="34"
                        height="34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          data-testid="bg-circle"
                          cx="17"
                          cy="17"
                          r="17"
                          fill="#F0F0F0"
                          stroke="#FF0000"
                          stroke-width="0"
                        ></circle>
                        <g class="scale-up" clip-path="url(#a)">
                          <path
                            data-testid="stroke-color-path"
                            d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z"
                            stroke="#4DB96B"
                            stroke-width="1.29983"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="a">
                            <path
                              data-testid="fill-bg-path"
                              fill="#F0F0F0"
                              transform="rotate(-45 23.52081528 1.25735931)"
                              d="M0 0h15.598v15.598H0z"
                            ></path>
                          </clipPath>
                        </defs></svg
                    ></span>
                    <p class="text-iblack-700 md:text-iblack-700"></p
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/visionaries/b5.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Tooey is Laying the Groundwork to<br class="hidden md:inline" />
                            Change Construction.
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            It started with the construction of his own home. Twenty years later, Procore’s CEO is now
                            rebuilding the entire construction industry.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/visionaries/b6.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Frank Has a “Single Source of Truth.”
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            How Snowflake has been one-of-a-kind since the very start.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
